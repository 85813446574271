import React from "react";
import io from 'socket.io-client';

const SOCKET_URL = "https://zaping.io:8444";
export const socket = io.connect(SOCKET_URL, { transport: ['websocket', 'polling'] });

export const SocketContext = React.createContext();

socket.on('connect', function() {
    // Gather user information and feature details
    const userId = window.localStorage.getItem('_userId') || 'Unknown User';
    const feature = window.location.pathname; // This can indicate the current feature or page (e.g., '/chat')

    console.log('Socket connected:', {
        socketId: socket.id,
        userId: userId,
        feature: feature,
        timestamp: new Date(),
    });

    // Send user identification to the server
    const param = { userId: userId, feature: feature };
    socket.send(param);
});
