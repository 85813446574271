import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";

import styled from "styled-components";

import axios from "axios";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { IconContext } from "react-icons";
import { IoMdAdd } from "react-icons/io";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosCloudUpload } from "react-icons/io";

import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import './style/trigger.css';

import Playground from "../components/Playground";

let userId = getStorage("_userId");

function getStorage(prm) {
  let _prm = window.localStorage.getItem(prm);
  if(_prm === "null"){
    //const newPort = 8445;
    //const newPath = '/';
    //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
  }else{
    if(_prm) {
      return _prm;
    }else{
      //const newPort = 8445;
      //const newPath = '/';
      //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
    }
  }
}

const redirectLogin = () => {
  const newPort = 8445;
  const newPath = '/';
  window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
};

let record;
let _triggersList;
let files;
let theFile;
let formData;
let formCreated;

export default function Trigger() {
  const navigate = useNavigate();         
  const { t } = useTranslation();
  let pictureFileExisting = [];

  const [fileInfo, setFileInfo] = useState(null);
  const [triggersList, setTriggerList] = useState([]);
  const [newTrigger, setNewTrigger] = useState({});

  const [form, setForm] = useState({
          userId: ""
        });
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const url = `https://www.zaping.io:8445/query_assistant/${userId}`;
        console.log('Making request to URL:', url);
    
        const response = await axios.get(url);
    
        // Assuming the existence of `record` in your response
        record = response.data;
    
        if (!record || !record?.gpt) {
          toast.error('Usuário não Encontrado!', {autoClose: 3000});
          redirectLogin();
          return;
        }
    
        if (record?.gpt?.expireDate < Date.now()) {
          toast.error('Plano Expirado!', {closeOnClick: true});
        } else {
          _triggersList = record?.gpt?.triggers;
          setTriggerList(_triggersList || []);
        }
    
      } catch (error) {
        console.error('Fetch error:', error);
        toast.error('Erro ao buscar dados!');
        redirectLogin();
      }
    };
    
    if (userId) {
      fetchData();      
    } else {
      console.log('User ID is not set. Redirecting to login.');
      redirectLogin();
    }

  }, [userId, navigate]);

  function changeFile(a, index) {
    const file = a.target.files[0]; // Get the selected file
    if (file) {
      // Create a new FormData instance
      const formData = new FormData();
      formData.append('uploadedFile', file); // Append the selected file to FormData
      
      let updatedTriggersList = [...triggersList];
      updatedTriggersList[index].formData = formData;
      updatedTriggersList[index].fileName = file.name;

      setTriggerList(updatedTriggersList); // This was missing

      // Update the state to hold file name
    }
  }  

  const unitList =  [
                     { key: "", label: "Selecione..." }, 
                     { key: "m", label: "minuto(s)"}, 
                     { key: "h", label: "hora(s)"}, 
                     { key: "d", label: "dia(s)"}, 
                    ]

  const addTrigger = (e) => {
    e.preventDefault();
    
    console.log(triggersList)

    let updatedTriggerList = [...triggersList];
    const uuid = uuidv4();
    let newTrigger = {id: uuid, fator: "", unit: "", message: ""}
    updatedTriggerList.push(newTrigger);
    setTriggerList(updatedTriggerList);
  };
  
  const deleteTrigger = (index, e) => {
    e.preventDefault();
  
    let updatedTriggerList = [...triggersList];
    updatedTriggerList.splice(index, 1); // Removes 1 element at the given index
    setTriggerList(updatedTriggerList);
    console.log(updatedTriggerList)
  };

  const Tooltip = ({ children, text }) => {
    return (
      <div className="tooltip-container">
        {children}
        <span className="tooltip-text">{text}</span>
      </div>
    );
  };

  function updateFator(value, index) {
    let updatedTriggersList = [...triggersList];
    updatedTriggersList[index].fator = value.fator;
    setTriggerList(updatedTriggersList); // This was missing
  }

  const onUpdateUnit = (e, index) => {
    let updatedTriggersList = [...triggersList];

    const newUnit = e.target.value;
    console.log(newUnit)
    updatedTriggersList[index].unit = newUnit ;
    setTriggerList(updatedTriggersList);
  };

  function updateMessage(value, index) {
    let updatedTriggersList = [...triggersList];
    updatedTriggersList[index].message = value.message;
    setTriggerList(updatedTriggersList); // This was missing
  }

  function updateUrl(value, index) {
    let updatedTriggersList = [...triggersList];
    updatedTriggersList[index].url = value.url;
    setTriggerList(updatedTriggersList); // This was missing
  }

  async function onSubmit(e) {
    e.preventDefault();

    if(triggersList?.length > 0){
      triggersList.forEach(trigger => {
        if(trigger?.fator){
          if(trigger?.fator > 0){
            let milliseconds = 0; // Default initialization
            
            switch(trigger?.unit.substr(0, 1)) {
              case "m": // If the unit is minutes
                milliseconds = trigger?.fator * 60 * 1000;
                break;
              case "h": // If the unit is hours
                milliseconds = trigger?.fator * 60 * 60 * 1000;
                break;
              case "d": // If the unit is days
                milliseconds = trigger?.fator * 24 * 60 * 60 * 1000;
                break;
              default:
                console.log("Unknown unit:" + trigger?.unit);
            }
            
            // Optionally, you can directly attach the calculated milliseconds to each item or just print them
            trigger.milliseconds = milliseconds;
            trigger.unit = trigger?.unit.substr(0, 1);
          }
        }
      });
    }

    let config = {
      method: 'post',
      url: `https://www.zaping.io:8445/update_triggers/${userId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: triggersList
    };
    const response = await axios(config);
    console.log(response)
    if(response?.statusText === "OK"){
      toast.success("Atualizado com sucesso!", {autoClose: 3000})
    }else{
      toast.error('ERRO ao atualizar!', {autoClose: 3000});
    }
  }

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add">
        <div className="playground">
          <Playground />
        </div>

        <div>
          <form onSubmit={onSubmit}>
            <br />
            <div className="form-group">
              <div className="row">   
                <div className="col-12">
                  <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                    <Tooltip text="Clique para incluir.">
                      <button id="butAddField" onClick={(e) => addTrigger(e)} className="butAddField"><IoMdAdd />Incluir</button>
                    </Tooltip>
                  </IconContext.Provider>
                </div>                
              </div>               

              <div className="row">
                <div className="col-1">
                </div>    
                <div className="col-1">
                  <label style={{ textAlign: 'right' }}>Após</label>
                </div>    
                <div className="col-3">
                </div>    
                <div className="col-7">
                  <label>Mensagem</label>
                </div>    
              </div>    

              {triggersList?.map((trigger, index) => (
                <div key={index}>

                  <div className="row">
                    <div className="col-1">
                      <Tooltip text="Clique para excluir.">
                        <button onClick={(e) => deleteTrigger(index, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                          <RiCloseCircleLine className="icon" />
                        </button>           
                      </Tooltip>
                    </div>
                    
                    <div className="col-1">
                      <input type="text" 
                              id="fator"
                              value={trigger?.fator} 
                              onChange={(e) => {
                                updateFator( { fator: e.target.value}, index );
                              }} 
                              className="form-control" />
                    </div>
                    <div className="col-3">
                      <Tooltip text="Clique para selecionar">
                        <select
                          value={trigger?.unit}
                          onChange={(e) => onUpdateUnit(e, index)} // Assuming onUpdateUnit is correctly implemented
                          style={{ fontSize: '100%' }}
                          className="form-control"
                        >  
                          {unitList?.map((unit, unitIndex) => ( // Using unitIndex in case `unit.key` is not unique or empty for the "Selecione..." option
                            <option key={unit.key || unitIndex} value={unit.key}>{unit.label}</option> // Correctly reference `unit.key` for value and `unit.label` for display
                          ))}
                        </select>                      
                      </Tooltip>
                      <label>da última interação</label>
                    </div>
                    <div className="col-7">                  
                      <textarea
                        type="text"
                        className="form-control"
                        value={trigger?.message} 
                        style={{height: '90px'}}
                        onChange={(e) => updateMessage( { message: e.target.value}, index )} 
                        placeholder="ex: Olá {nome}! Passando aqui para lembrar que..." 
                      />   
                    </div>

                    <div className="col-1">
                    </div>

                    <div className="col-1">
                    {/*
                      <form id="formDataP" name="formDataP" method="post" encType="multipart/form-data">
                          <label htmlFor="productImage" className="fileInputLabel btn btn-primary shadow1">
                          <IoIosCloudUpload />
                          <input type="file" 
                                id="productImage" 
                                name="filesP" 
                                accept=".jpg, .png, .jpeg, .mp3, .mp4, .pdf"
                                onChange={(a) => changeFile(a, index)} 
                                style={{ display: "none" }} />
                        </label>   
                      </form>  
                    */}
                    </div>
                    <div className="col-3 fileNameLabel">
                      <div id="fileLabel">{trigger?.fileName ? `${trigger?.fileName}` : ''}</div>
                    </div>

                    <div className="col-7">   
                      <label htmlFor="url">Url do arquivo (imagem/audio/video/documento):</label>
                        <input type="text" 
                            id="url"
                            value={trigger?.url} 
                            onChange={(e) => {
                              updateUrl( { url: e.target.value}, index );
                            }} 
                            placeholder="https://zaping.io/images/logoZaping.png" 
                            className="form-control" />                                                
                    </div>

                  </div>
                  <br />

                </div>
              ))}              

              <div>
                <br />
                <input
                  type="submit"
                  value="Salvar"
                  id="butSave"
                  className="btn btn-primary shadow1"
                />
              </div>

            </div>
          </form>             
        </div>
 
        <ToastContainer />

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .fileNameLabel {
    color: whiteSmoke;
    padding-top: 25px;
  }
  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;
  }
  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
  .same-line {
    display: flex;
    align-items: center; /* Vertically aligns items in the center */
    gap: 10px; /* Adjust the space between label and input */
    max-width: 100%; 
    box-sizing: border-box;  
  }

  .same-line label {
      margin: 0; /* Remove default margins */
      max-width: 100%; 
      box-sizing: border-box;    
  }

  .same-line input {
      flex: 1; /* Make the input take up the remaining space */
      max-width: 100%; 
      box-sizing: border-box;    
  }

  .same-line button {
    flex: 1; /* Make the input take up the remaining space */
    max-width: 100%; 
    box-sizing: border-box;    
}
`;
