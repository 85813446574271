import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import styled from "styled-components";
import axios from "axios";
import { ProgressBar } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { RiCloseCircleLine } from "react-icons/ri";

import './style/widget-data.css';
import Playground from "../components/Playground";

export default function QR() {
  let start = 0;
  let initInstance;

  const [loading, setLoading] = useState(true);
  const [isButtonVisible, setButtonVisible] = useState(false);

  const [inc, setInc] = useState();

  const navigate = useNavigate();
  const [form, setForm] = useState({
    qrcode: "",
  });
  
  async function deleteInstance() {
    try {
        await axios.delete(`https://www.zaping.io:8443/instance/logout/${userId}`);
        await axios.delete(`https://www.zaping.io:8443/instance/delete/${userId}`);
        console.log("User logged out and deleted successfully.");
        toast.success("Desconectado com Sucesso!", {autoClose: 2000});
        initInstance();
    } catch (error) {
        // Handle the error appropriately
        if (error.response) {
            // The request was made, and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("Error response:", error.response.data);
            console.error("Status code:", error.response.status);
            console.error("Headers:", error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error("Error request:", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error message:", error.message);
        }
    }
  }

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }

  let userId = getStorage("_userId");

  useEffect(() => {
    setLoading(true);

    initInstance = async () => {
      try {
        console.log('userId');
        console.log(userId);
        const response = await axios.post(`https://www.zaping.io:8445/init-instance/${userId}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        let parameter = response.data;

        console.log(parameter)
        
        if(parameter.message === 'connected') {
          toast.success("QRCode já conectado!", {autoClose: 3000});
          setLoading(false); // Set loading to false once the response is received
          setButtonVisible(true);
          return;
        } else if(parameter.qrImg) {
          setLoading(false); // Set loading to false once the response is received
          console.log('img received!')
          setForm({ ...form, qrcode: parameter.qrImg });
        } else {
          setLoading(false); // Set loading to false if there's an error
          console.error('Error initiating instance:', parameter.message);
        }
      } catch (error) {
        setLoading(false); // Set loading to false if there's an error
        console.error('Error initiating instance:', error);
      }
    };

    initInstance();
    
    const interval = setInterval(() => {
      start = start + 1
      if(start>100){
        start = 100
      }
      setInc(start);
    }, 100);


    const inter = setInterval(() => {
      initInstance(); // Call the initInstance function every 20 seconds
    }, 20000);

    return () => {
      clearInterval(inter); // Clear the interval when the component unmounts
    }

  }, [userId, navigate]);  

  return (
    <Container>
      <div className="container-add usuario-inclui">
        <Playground />

        <div>
          <br />
          <div className="form-group qr-container">
            <br />
            <br />
            <label htmlFor="qrcode">Leia o QRCode com o app Whats do celular: </label>
            <br />
            <br />
            <br />
            <br />
            <div className="col-4">
              <img id="imageThumb" src={form.qrcode}></img>
              {loading && <ProgressBar animated now={inc} />}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {isButtonVisible && (
              <button 
                id="butDeleteInstance" 
                onClick={deleteInstance} 
                style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent' }}
              >
                <RiCloseCircleLine className="icon" />
              </button>
            )}        
          </div>
        </div>

        <ToastContainer />

      </div>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529;
  .container-add {
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 1fr 3fr; /* Use fractions of available space */
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 1fr 2fr; /* Adjust for different screen size */
    }
  }
`;
